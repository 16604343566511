  import React from 'react';
  import styled from "styled-components";
  import {theme} from "../../styles/Theme";
  import { useTranslation } from 'react-i18next';

  type MenuProps = {
      display?: string,
  }
  export const Menu = (props: MenuProps) => {
      const Scroll = (div: string) => {
          const featuresElement = document.getElementById(div);
          if (featuresElement) {
              featuresElement.scrollIntoView({ behavior: 'smooth', block: 'start' });

          }
      };

      const { t, i18n } = useTranslation();

      const handleLangSwitch = () => {
        const newLang = i18n.language === "ru" ? "en" : "ru"; 
        i18n.changeLanguage(newLang);
      };

      const getFlagSrc = (lang: string) => {
        return lang === "en" ? "/flags/ru.png" : "/flags/en.png"; 
      };

      return (
          <StyledMenu display={props.display}>
              <StyledUl>
                  <li onClick={() =>  Scroll('possibilities') }>{t('Header.Menu.possibilities')}</li>
                  <li onClick={() =>  Scroll('features')}>{t('Header.Menu.features')}</li>
                  <li onClick={() => Scroll('safety')}>{t('Header.Menu.safety')}</li>
                  <li onClick={() => Scroll('regions')}>{t('Header.Menu.regions')}</li>
                  <li onClick={handleLangSwitch}>
                    <FlagWrapper>
                      <Flag src={getFlagSrc(i18n.language)} alt="language flag" />
                      <span>{t('Header.language')}</span>
                    </FlagWrapper>
                  </li>
                  </StyledUl>
          </StyledMenu>
      );
  };

  const StyledUl = styled.ul `
    display: flex;
    gap: 60px;
    color: ${theme.colors.fontColorSecondary};
    font-weight: 400;
    font-size: 18px;
    @media (max-width: 426px) {
      flex-direction: column;
      text-align: start;
      gap: 16px;
    }
    @media (min-width: 427px) and (max-width: 1024px) {
      flex-direction: column;
      text-align: start;
      gap: 16px;
    
    } 
    li:hover {
      cursor: pointer;
    }
    @media (min-width: 1024px) and (max-width: 1440px) {
      // background: ${theme.colors.fontColorWhite};
      gap: 16px;



    }
  `
  const StyledMenu = styled.menu <MenuProps>`
    display: ${props => props.display};
    
  `
  const Flag = styled.img`
    width: 26px;
    height: 26px;
    margin-right: 8px;
    vertical-align: middle;
  `;
  
  const FlagWrapper = styled.div`
    display: flex;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    cursor: pointer;
    @media (max-width: 1024px) {
      display: inline;
      text-align: start;
    }
  `