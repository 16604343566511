import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locales/en.json";
import ru from "./locales/ru.json";

const resources = {
  en: {
    translation: en
  },
  ru: {
    translation: ru
  }
} as const;

i18next
  .use(initReactI18next)
  .init({
    lng: "ru",
    fallbackLng: "ru",
    debug: true,
    interpolation: {
      escapeValue: false
    },
    resources
  });

export default i18next;